import React, { useEffect, useState, useCallback } from 'react';

import { Link } from 'react-router-dom';

import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import axios from 'axios';

import { Title, Breadcrumbs, Breadcrumb } from 'components/Common';

import emptyHelpImg from 'assets/img/svg/empty-helper.svg';

import { useDropzone } from 'react-dropzone';

import {
  Loading,
  Button,
  SearchSelect,
  Label,
  Modal,
  Error,
} from '@cloudez/cloudez-design-system';

import history from 'services/history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faChevronRight,
  faTimes,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';
import { getUsersService } from 'services/user';

import { CustomInput, CustomTextArea } from 'components/NewComponents';

import { useDocTitle } from 'hooks/docTitle';
import { useAuth } from 'hooks/auth';
import {
  createTicketService,
  createTicketMessageService,
} from 'services/ticket';
import {
  addTicketToMigrationService,
  getStatusMigrationService,
} from 'services/friday';
import toastError from 'utils/toastError';
import { toast } from 'react-toastify';
import { useLayout } from 'hooks/layout';
import {
  Header,
  TicketWrapper,
  TicketForm,
  TicketHelp,
  MessageInput,
  AttachmentBox,
  Flexbox,
} from './styles';

import ResourceItem from './Resource/ResourceItem';
import Resource from './Resource';

import { useHelp } from './hook';
import { HelpBaseProvider } from './context';
import { schema, staffSchema } from './schemas';
import SuccessModal from './SuccessModal';

interface FormValues {
  target_type: {
    value: any;
  };
  target_id: any;
  summary: any;
  team: { value: any };
  owner: { value: any };
  message: any;
}

const TicketCreate: React.FC = () => {
  useDocTitle('Novo Ticket - Suporte — Cloudez');

  const { user } = useAuth();
  const theme = useLayout();

  const { getHelpBase, helps, setHelps, helpsLoading } = useHelp();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [resource, setResource] = useState(null);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState<any>('');
  const [users, setUsers] = useState(null);
  const [block, setBlock] = useState(false);
  const [wasClicked, setWasClicked] = useState(false);

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(user.is_staff ? staffSchema : schema),
  });

  const targetType = watch('target_type')?.value;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const migrationId = urlParams.get('migration');

  const { acceptedFiles, getInputProps, getRootProps } = useDropzone();

  const filesFormat = acceptedFiles?.map(
    (item: any) => item.path.split('.')[1],
  );
  const isValid =
    acceptedFiles.length === 0 ||
    filesFormat.includes('txt') ||
    filesFormat.includes('png') ||
    filesFormat.includes('jpeg') ||
    filesFormat.includes('jpg') ||
    filesFormat.includes('pdf') ||
    filesFormat.includes('json') ||
    filesFormat.includes('word');

  const selectedResourceTitle = {
    invoice: 'Fatura Selecionada',
    cloud: 'Cloud Selecionado',
    domain: 'Domínio Selecionado',
    website: 'Website Selecionado',
    email: 'Email Selecionado',
  }[targetType];

  const removeFile = file => () => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
  };

  const areas = [
    { value: 1, label: 'Suporte' },
    { value: 2, label: 'Financeiro' },
    { value: 3, label: 'Comercial' },
    { value: 4, label: 'Migração' },
    { value: 5, label: 'Spam' },
    { value: 6, label: 'Alertas' },
    { value: 7, label: 'Painel' },
    { value: 1, label: 'Outros' },
  ];

  const categories = [
    { value: 'website', label: 'Website' },
    { value: 'email', label: 'Email' },
    { value: 'domain', label: 'Domínio' },
    { value: 'cloud', label: 'Cloud' },
    { value: 'invoice', label: 'Fatura' },
  ];

  const [createdTicketId, setCreatedTicketId] = useState();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const onSubmit = async e => {
    setWasClicked(true);
    if (!isValid) {
      toast.error(
        'Formato de arquivo anexado é inválido. Os tipos de arquivo aceitos são: txt, jpg/jpeg, png, pdf, json e word.',
      );
      return;
    }
    setLoading(true);

    const ownerLabel = e?.owner?.label;

    try {
      const payload = {
        ...e,
        owner_email:
          user.is_staff || (migrationId && ownerLabel)
            ? e.owner.label
            : user.email,
        target_type: e?.target_type.value,
        team: e?.team.value,
        target_id: e?.target_id,
      };
      delete payload.message;
      delete payload.owner;
      if (!targetType) delete payload.target_type;
      if (!resource?.id && !migrationId) delete payload.target_id;
      const { data } = await createTicketService(payload);

      const messagePayload = new FormData();
      messagePayload.append('ticket', data.id.toString());
      messagePayload.append('text', e.message);
      messagePayload.append('author', user.id.toString());
      if (acceptedFiles[0]) messagePayload.append('file', acceptedFiles[0]);
      await createTicketMessageService(messagePayload);

      if (migrationId) {
        await addTicketToMigrationService(Number(migrationId), {
          ticket_id: data.id,
        });
      }

      setShowSuccessModal(true);
      setCreatedTicketId(data.id);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      const errorMessage =
        e?.response?.data?.file?.[0] || 'Erro ao abrir o Ticket.';
      toast.error(errorMessage);
    }
  };

  const handleLoadOptions = async inputValue => {
    const {
      data: { results },
    } = await getUsersService({ search: inputValue });
    return results.map(u => ({ value: u.id, label: u.email }));
  };

  const getCategories = useCallback(async () => {
    try {
      const { data } = await axios.get(
        'https://help.cloudez.io/wp-json/wp/v2/categories',
      );
      if (user.id === theme.admin || user.is_staff) {
        return data.map(u => ({ value: u.id, label: u.name }));
      }
      return data
        .filter(u => u.name !== 'Empresa')
        .map(u => ({ value: u.id, label: u.name }));
    } catch (errors) {
      console.log('errors', errors);
    }
  }, []);

  useEffect(() => {
    async function getUsers() {
      const {
        data: { results },
      } = await getUsersService();

      setUsers(results.map(u => ({ value: u.id, label: u.email })));
    }

    if (!users) getUsers();
  }, [users]);

  useEffect(() => {
    if (!title || title === null) {
      setHelps([]);
    }
    if (title) {
      getHelpBase({ categories: title?.value });
    }
  }, [title]);

  useEffect(() => {
    if (targetType && targetType !== 'migration') {
      setOpenModal(true);
      setResource(null);
    }
  }, [targetType]);

  useEffect(() => {
    if (openModal === undefined && resource === null) {
      setValue('target_type', null);
    }
  }, [openModal, resource]);

  useEffect(() => {
    if (resource?.id) {
      setValue('target_id', resource.id);
    }
  }, [resource?.id]);

  useEffect(() => {
    if (migrationId) {
      const getMig = async () => {
        setBlock(true);
        const { data } = (await getStatusMigrationService(
          Number(migrationId),
        )) as any;
        setBlock(false);

        setValue(
          'summary',
          `Migração de ${data.migration_target} ${
            data.dest_nodeapp?.domain || data.dest_zoneapp?.domain
          }`,
        );

        setValue('target_id', Number(migrationId));
        categories.push({ value: 'migration', label: 'Migração' });
        setValue(
          'target_type',
          categories.filter(item => item.value === 'migration')[0],
        );
        setValue('team', areas.filter(item => item.value === 4)[0]);

        if (users && migrationId) {
          setValue('owner', users.filter(item => item.label === user.email)[0]);
        }
      };

      getMig();
    }
  }, [migrationId, users]);

  useEffect(() => {
    if (createdTicketId && showSuccessModal === undefined) {
      history.push(`/suporte/${createdTicketId}`);
    }
  }, [createdTicketId, showSuccessModal]);

  return (
    <>
      <SuccessModal
        id={createdTicketId}
        show={showSuccessModal}
        setShow={setShowSuccessModal}
      />
      <Header>
        <Title className="title">
          <Breadcrumbs className="breadcrumbs">
            <Breadcrumb>
              <Link to="/dashboard">Home</Link>
            </Breadcrumb>
            <FontAwesomeIcon icon={faChevronRight} />
            <Breadcrumb>
              <Link to="/suporte">Suporte</Link>
            </Breadcrumb>
            <FontAwesomeIcon icon={faChevronRight} />
            <Breadcrumb>
              <span className="new">Novo Ticket</span>
            </Breadcrumb>
          </Breadcrumbs>
          Novo Ticket
        </Title>
      </Header>
      <TicketWrapper>
        <TicketHelp>
          <p className="title">Consulte a base do conhecimento</p>
          <SearchSelect
            name="help"
            placeholder="Selecione a categoria desejada"
            value={title}
            onChange={e => setTitle(e)}
            loadOptions={getCategories}
          />
          {helpsLoading ? (
            <div className="empty">
              <Loading />
            </div>
          ) : (
            <>
              {helps?.length === 0 ? (
                <div className="empty">
                  {title && (
                    <span>
                      Infelizmente não
                      <br /> encontramos nenhum artigo.
                    </span>
                  )}
                  <img src={emptyHelpImg} alt="Nada encontrado" />
                </div>
              ) : (
                <div className="helpList">
                  <ul>
                    {helps?.map(help => (
                      <a
                        key={help.id}
                        href={help.link}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <li>{help.title.rendered}</li>
                      </a>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
        </TicketHelp>
        <TicketForm>
          <p className="label">Abertura de ticket</p>
          <p className="instruction">Dicas para abertura de um ticket.</p>
          <ul>
            <li>
              Seja o mais claro possível no <b>Título do Ticket</b> e na{' '}
              <b>Descrição do Ticket</b>.
            </li>
            <li>
              Utilize o campo de anexo de arquivo para enviar{' '}
              <b>informações complementares.</b>
            </li>
          </ul>
          <form
            style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <CustomInput
              label="Título do Ticket"
              placeholder="Ex.: Migração de site"
              disabled={!!migrationId}
              block
              name="summary"
              register={register}
              error={errors?.summary?.message}
            />
            {(user.is_staff || user.is_lv1_support) && (
              <Flexbox flexDir="column" spacing="2px">
                <Label>Escolher um usuário</Label>
                <Controller
                  control={control}
                  name="owner"
                  render={({ field }) => (
                    <SearchSelect
                      placeholder="Escolha um usuário"
                      loadOptions={handleLoadOptions}
                      isDisabled={!!migrationId}
                      defaultValue={getValues('owner')}
                      name="owner"
                      {...field}
                    />
                  )}
                />
                <Error>{errors?.owner?.value?.message}</Error>
              </Flexbox>
            )}
            <Flexbox>
              <Flexbox w="100%" flexDir="column" spacing="2px">
                <Label>Área de atendimento</Label>
                <Controller
                  control={control}
                  name="team"
                  render={({ field }) => (
                    <SearchSelect
                      async={false}
                      placeholder="Selecione a Área de Atendimento"
                      defaultValue={getValues('team')}
                      isDisabled={!!migrationId}
                      loadOptions={areas}
                      name="team"
                      {...field}
                    />
                  )}
                />
                <Error>{errors?.team?.value?.message}</Error>
              </Flexbox>
              <Flexbox w="100%" flexDir="column" spacing="2px">
                <Label>Categoria</Label>
                <Controller
                  control={control}
                  name="target_type"
                  render={({ field }) => (
                    <SearchSelect
                      async={false}
                      isDisabled={!!migrationId}
                      placeholder="Selecione a Categoria"
                      defaultValue={getValues('target_type')}
                      height="40px"
                      loadOptions={categories}
                      {...field}
                    />
                  )}
                />
                <Error>
                  {errors?.target_type?.value?.message ||
                    (errors?.target_type && 'Este campo é obrigatório.')}
                </Error>
              </Flexbox>
            </Flexbox>
            <Modal
              style={{ minWidth: '540px', width: 'auto' }}
              show={openModal}
              setShow={setOpenModal}
            >
              {targetType && (
                <Resource
                  type={targetType}
                  setResource={setResource}
                  setOpenModal={setOpenModal}
                  resource={resource}
                />
              )}
            </Modal>
            {resource && targetType && (
              <Flexbox spacing="10px" flexDir="column">
                <Label>{selectedResourceTitle}</Label>
                <ResourceItem
                  gap="110px"
                  c={resource}
                  type={targetType}
                  removeResource={() => {
                    setResource(null);
                    setOpenModal(true);
                  }}
                />
              </Flexbox>
            )}
            <Flexbox flexDir="column" spacing="0px">
              <MessageInput file={acceptedFiles[0]}>
                <div className="textarea">
                  <CustomTextArea
                    block
                    label="Descrição do Ticket"
                    background
                    name="message"
                    placeholder="Digite aqui sua mensagem"
                    register={register}
                    error={errors?.message?.message}
                  />
                  <AttachmentBox {...getRootProps({ className: 'dropzone' })}>
                    {acceptedFiles.length > 0 ? (
                      <>
                        {acceptedFiles.map((file: any) => (
                          <div key={file?.name} className="fileContainer">
                            <div
                              onClick={() => removeFile(file)}
                              className="fileBox"
                            >
                              <span>{file?.name}</span>
                              <FontAwesomeIcon icon={faTimes} />
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <span className="text">
                        Clique para adicionar ou arraste o arquivo
                      </span>
                    )}
                    <input {...getInputProps()} />
                  </AttachmentBox>
                </div>
              </MessageInput>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  disabled={block || wasClicked}
                  type="submit"
                  width="127px"
                  icon
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    Enviar
                    <FontAwesomeIcon
                      icon={loading ? faSpinner : faPaperPlane}
                      spin={loading}
                    />
                  </div>
                </Button>
              </div>
            </Flexbox>
          </form>
        </TicketForm>
      </TicketWrapper>
    </>
  );
};

export default props => (
  <HelpBaseProvider>
    <TicketCreate {...props} />
  </HelpBaseProvider>
);
